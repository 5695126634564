import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Button, Form, Row, Col, FloatingLabel } from "react-bootstrap";

import moment from "moment";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import Stack from "@mui/material/Stack";

import "./form-modal.css";
import { url } from "../baseUrl";
import { getDeptNames, getFeedback } from "../actions";
import { toast } from "react-toastify";

const FormModal = () => {
  const [validated, setValidated] = useState(false);
  const [ticketData, setTicketData] = useState({
    ticketNumber: "",
    summary: "",
    systemName: "",
    departName: "",
    description: "",
    assignedTo: "",
    category: "",
    closedOn: new Date(),
    createdOn: new Date(),
    createdBy: "",
    priority: "",
    status: "",
  });
  const [closedOn, setClosedOn] = useState(new Date());
  const [createdOn, setCreatedOn] = useState(new Date());
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState();
  const [role, setRole] = useState();
  const [userData, setUserData] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [deptData, setDeptData] = useState([]);

  const getUserData = async () => {
    // console.log("userId getUserData ==>", userId);
    let fd = await axios.post(`${url}/user/getIndividualUser`, { id: userId });
    // console.log("userData from getUserData ===>", fd.data[0]);
    setUserData(fd.data[0]);
  };

  useEffect(() => {
    var token = localStorage.getItem("token");
    var decoded = jwt_decode(token);
    // console.log(decoded);
    setUserId(decoded.id);
    setUserName(decoded.username);
    setRole(decoded.isAdmin);
    getUserData();
  }, [userId]);

  const getFeedbackData = async () => {
    const response = await getFeedback();
    console.log("feedback response ", response);
    setFeedbackData(response?.data?.data);
    return response;
  };
  const getDeptData = async () => {
    const response = await getDeptNames();
    console.log("response >>>", response.data?.data);
    setDeptData(response.data?.data);
    return response;
  };

  useEffect(() => {
    getFeedbackData();
    getDeptData();
    clear();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      toast.warn("Please fill all fields");
      setValidated(false);
      return;
    }

    const submitData = {
      ticketNumber: Number(lastId),
      summary: ticketData.summary,
      systemName: ticketData.systemName,
      departName: ticketData.departName,
      description: ticketData.description,
      assignedTo: ticketData.assignedTo,
      category: ticketData.category,
      closedOn: moment(closedOn).format("YYYY-MM-DD"),
      createdOn: moment().format("YYYY-MM-DD"),
      createdBy: userName,
      priority: ticketData.priority,
      status: ticketData.status,
      timeSpent: 0,
      timeToResolve: 0,
    };
    setValidated(true);
    console.log("submitData ====>", submitData);

    axios({
      method: "POST",
      data: submitData,
      url: `${url}/feedback/createFeedback`,
    })
      .then((response) => {
        // console.log("response from createFeedback ====>", response);
        toast.success("Ticket Inserted successfully ");
        if (response.status === 200) {
          getFeedbackData();
          setValidated(false);
          clear();
        }
      })
      .catch((error) => {
        toast.error("Server Error!");
        setValidated(false);
        console.log("error from createFeedback =====>", error);
      });
  };

  const clear = () => {
    setTicketData({
      summary: "",
      departName: "",
      description: "",
      assignedTo: "",
      category: "",
      closedOn: "",
      createdOn: "",
      createdBy: "",
      priority: "",
      status: "",
      timeSpent: "",
      timeToResolve: "",
    });
  };

  const [lastId, setLastId] = useState();
  console.log("last id ==>", lastId);
  const lastIds = (feedbackData) => {
    let lastTicket;
    feedbackData?.forEach((item, index, arr) => {
      let lastId = arr.slice(0);
      lastTicket = lastId[0].id;
      return lastTicket;
    });
    return lastTicket;
  };
  useEffect(() => {
    setLastId(lastIds(feedbackData) + 1);
  }, [lastIds(feedbackData), feedbackData]);

  return (
    <div className="ticket-form">
      <div className="heading pb-2 text-center">
        <h3 className="heading">Support Ticket Form</h3>
      </div>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
        className="w-100"
      >
        <Row className="mb-3">
          {/* <Row className="col-12"> */}
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label style={{ fontWeight: "bold" }}>
              {" "}
              Ticket Number
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Ticket Number"
              disabled
              // defaultValue={`#${lastId + 1}`}
              // defaultValue={`#${lastId + 1}`}
              defaultValue={lastId}
              // onChange={(e) =>
              //   setTicketData({
              //     ...ticketData,
              //     ticketNumber: e.target.value,
              //   })
              // }
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom04">
            <Form.Label style={{ fontWeight: "bold" }}> System Name</Form.Label>
            <Form.Select
              required
              value={ticketData.systemName}
              onChange={(e) =>
                setTicketData({
                  ...ticketData,
                  systemName: e.target.value,
                })
              }
              aria-label="Default select example"
            >
              <option value="" selected disabled hidden>
                Choose system Name
              </option>
              <option value="Defect Handling">Defect Handling</option>
              <option value="OFS">OFS</option>
              <option value="BOS">BOS</option>
              <option value="CL">CL</option>
              <option value="CIL">CIL</option>
            </Form.Select>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            {/* <Form.Control.Feedback type="invalid">
                Please provide a valid Depart Name.
              </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group
            as={Col}
            // md="12"
            md="6"
            // className="w-100"
            controlId="validationCustom04"
          >
            <Form.Label style={{ fontWeight: "bold" }}> Department</Form.Label>
            <Form.Select
              required
              //   defaultValue="Department Name"
              value={ticketData.departName}
              onChange={(e) =>
                setTicketData({
                  ...ticketData,
                  departName: e.target.value,
                })
              }
              aria-label="Default select example"
            >
              {/* <option>Department</option> */}
              <option value="" selected disabled hidden>
                Choose here
              </option>
              {deptData?.map((dept, i) => (
                <option value={dept.DepartName} key={i}>
                  {dept.DepartName}
                </option>
              ))}
            </Form.Select>
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
            {/* <Form.Control.Feedback type="invalid">
                Please provide a valid Depart Name.
              </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label style={{ fontWeight: "bold" }}> Summary</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Summary"
              //   defaultValue="Summary"
              value={ticketData.summary}
              onChange={(e) =>
                setTicketData({
                  ...ticketData,
                  summary: e.target.value,
                })
              }
            />
            {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group as={Col} md="12" controlId="validationCustomUsername">
            <Form.Label style={{ fontWeight: "bold" }}>Description</Form.Label>
            <FloatingLabel
              controlId="floatingTextarea2"
              label="Brief Message..."
            >
              <Form.Control
                as="textarea"
                placeholder="Leave a comment here"
                style={{ height: "75px" }}
                value={ticketData.description}
                onChange={(e) =>
                  setTicketData({
                    ...ticketData,
                    description: e.target.value,
                  })
                }
              />
            </FloatingLabel>
          </Form.Group>
          <Form.Group
            as={Col}
            md="6"
            // className="w-100"
            controlId="validationCustom03"
          >
            <Form.Label style={{ fontWeight: "bold" }}> Priority</Form.Label>
            <Form.Select
              value={ticketData.priority}
              onChange={(e) =>
                setTicketData({
                  ...ticketData,
                  priority: e.target.value,
                })
              }
              aria-label="Default select example"
            >
              <option value="" selected disabled hidden>
                Choose here
              </option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </Form.Select>
            {/* <Form.Control.Feedback type="invalid">
                Please provide a valid priority.
              </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group
            as={Col}
            md="6"
            // className="w-100"
            controlId="validationCustom05"
          >
            <Form.Label style={{ fontWeight: "bold" }}> Status</Form.Label>
            {/* <Form.Control type="text" placeholder="Status" required /> */}
            <Form.Select
              aria-label="Default select example"
              value={ticketData.status}
              onChange={(e) =>
                setTicketData({ ...ticketData, status: e.target.value })
              }
            >
              <option value="" selected disabled hidden>
                Choose here
              </option>
              <option value="Open">Open</option>
              {/* <option value="Closed">Closed</option> */}
              {/* <option value="Waiting">Waiting</option> */}
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="6"
            // className="w-100"
            controlId="validationCustom011"
          >
            <Form.Label style={{ fontWeight: "bold" }}> Assigned To</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={ticketData.assignedTo}
              onChange={(e) =>
                setTicketData({
                  ...ticketData,
                  assignedTo: e.target.value,
                })
              }
            >
              <option value="" selected disabled hidden>
                Choose here
              </option>
              <option value="Support Team">Support Team</option>
            </Form.Select>
          </Form.Group>
          <Form.Group
            as={Col}
            md="6"
            // className="w-100"
            controlId="validationCustom012"
          >
            <Form.Label style={{ fontWeight: "bold" }}> Category</Form.Label>
            <Form.Select
              required
              defaultValue="abc"
              value={ticketData.category}
              onChange={(e) =>
                setTicketData({
                  ...ticketData,
                  category: e.target.value,
                })
              }
              aria-label="Default select example"
            >
              <option value="" selected disabled hidden>
                Choose here
              </option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="Other">Other</option>
            </Form.Select>
          </Form.Group>

          <Form.Group
            as={Col}
            md="6"
            // className="w-100"
            controlId="validationCustom01111"
          >
            <Form.Label style={{ fontWeight: "bold" }}> Created On</Form.Label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3} className="hover-on-date">
                <MobileDatePicker
                  // label="For mobile"
                  value={createdOn}
                  onChange={(newValue) => {
                    setCreatedOn(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField className="rounded dates-input" {...params} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Form.Group>
          <Form.Group
            as={Col}
            md="6"
            // className="w-100"
            controlId="validationCustom0111"
          >
            <Form.Label style={{ fontWeight: "bold" }}> Closed On</Form.Label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack spacing={3} className="hover-on-date">
                <MobileDatePicker
                  // label="For mobile"
                  value={closedOn}
                  onChange={(newValue) => {
                    setClosedOn(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField className="rounded dates-input" {...params} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
          </Form.Group>
        </Row>
        <div className="row mx-auto">
          <Button type="submit" className="w-100 btn--primary">
            Submit
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormModal;
