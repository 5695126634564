import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getFeedback } from "../../components/actions";
import TicketBox from "../../components/ticket-box/TicketBox";
import "./myTickets.css";

const MyTickets = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  const getFeedbackData = async () => {
    const response = await getFeedback();
    console.log("resposne ===>", response);
    setFeedbackData(response.data.data);
    return response;
  };
  useEffect(() => {
    getFeedbackData();
  }, []);

  console.log("feedbackData ====>", feedbackData);

  return (
    <div
      // className="bg-color-light"
      // style={feedbackData.length > 6 ? null : { height: "100vh" }}
    >
      <div className="row py-3">
        <h1 className="text-center heading">My Tickets</h1>
      </div>
      <div className="row mx-auto">
        {feedbackData.map((item) => {
          return (
            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 px-0" key={item}>
            {/* <div className="col-md-4 col-sm-6 px-0" key={item}> */}
              <TicketBox
                ticketNo={item.ticketNumber}
                systemName={item.systemName}
                ticketTitle={item.summary}
                departName={item.departName}
                description={item.description}
                assignedTo={item.assignedTo}
                category={item.category}
                closedOn={item.closedOn}
                createdOn={item.createdOn}
                createdBy={item.createdBy}
                priority={item.priority}
                status={item.status}
                timeSpent={item.timeSpent}
                timeToResolve={item.timeToResolve}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MyTickets;
