import React from "react";
import { useNavigate } from "react-router-dom";
import {useLocation} from "react-router-dom";
import { FazerAnimation } from "react-fazer-animation";
 const TSetter = () => {
  const navigate = useNavigate();
  const search = useLocation().search;

  const token = new URLSearchParams(search).get('token');
  console.log(token);

  //Setting token in local Storage and Redirecting to dashboard

  //Checking Token
  if (!token) {
    console.log("in if block");
  }
  //if token is avalaible then redirect to dashboard
  else {
    console.log("in else block");
    localStorage.setItem("token", token);
    setTimeout(() => {
        navigate("/Dashboard");
    }, 1000);
  }

  // Funtion for redirecting
  const handleSignIn = () => {
    window.location.href = "https://finehhdigital.com/";
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <a style={{ cursor: "pointer" }} href onClick={handleSignIn}>
        <FazerAnimation
          title={"Sign In To Continue..."}
          bodyColor={"cyan"}
          longfazersColor={"linear-gradient(black,red)"}
        />
      </a>
    </div>
  );
};

export default TSetter