import React from "react";
import { ReadMoreText } from "../readmore/ReadMoreText";
import "./ticketBox.css";

const TicketBox = (props) => {
  return (
    <div className="ticket__wrapper">
      <div className="ticket__content enabled">
        <div className="ticket__content__item rounded">
          <div className="card-box__item">
            <div className="card-box__item__profile">
              <div className="d-flex justify-content-between">
                <h6 className="card-box__item__name primary--color ticket-font-size">
                  Ticket No: {"#" + props.ticketNo || "-"}
                </h6>
                <h6 className="card-box__item__name">
                  {props.systemName || "System Name"}
                </h6>
              </div>
              <h4 className="card-box__item__name">
                {props.ticketTitle || "Title Name"}
              </h4>
              <div className="card-box__item__experience">
                <b>Description: </b>
                <ReadMoreText>{props.description || "-"}</ReadMoreText>
              </div>
              <div className="d-flex justify-content-around">
                <div className="card-box__item__location">
                  <b>Priority: </b>
                  {props.priority || "-"}
                </div>
                <div className="card-box__item__experience">
                  <b>Status: </b>
                  <span>{props.status || "-"}</span>
                </div>
              </div>
              {/* <div className="d-flex justify-content-around">
                <div className="card-box__item__location">
                  <b>Category: </b>
                  {props.category || "-"}
                </div>
              </div> */}
            </div>
            <div
              className={`card-box__item__bottom card-box__item__bottom--${
                props.status === "Closed"
                  ? "closed"
                  : props.status === "Waiting"
                  ? "waiting"
                  : props.status === "Open"
                  ? "open"
                  : "play"
              }`}
            >
              {/* <div>{props.ticketNo || }</div> */}
              <div>
                <b>Assign To: </b>
                <span>{props.assignedTo || " "}</span>
              </div>
              <div>
                <b>Created By: </b>
                <span>{props.createdBy || " "}</span>
              </div>
              <div>
                <b>Created On: </b>
                <span>{props.createdOn || "No Date"}</span>
              </div>
              <div>
                <b>Closed On: </b>
                <span>{props.closedOn || "No Date"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketBox;
