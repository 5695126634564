import axios from "axios";
import { url } from "../baseUrl/index";

const DEPT_URL = "https://ofsb.finehhdigital.com/api";

export const getFeedback = async () => {
  const getFeedbackData = await axios.get(`${url}/feedback/selectFeedback`);
  return getFeedbackData;
};

export const getDeptNames = async () => {
  const getDeptNamesData = await axios.get(
    `${DEPT_URL}/department/SelectDepartment`
  );
  return getDeptNamesData;
};
