import { Fragment } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard2 from "./containers/Dashboard-2/Dashboard2";
import Tsetter from './components/Tsetter'
function App() {
  return (
    <Fragment>
      <Routes>
        <Route exact path="/" element={<Tsetter />} />
        <Route  path="/Dashboard" element={<Dashboard2 />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
    
  );
}

export default App;
