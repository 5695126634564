import React from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import FormModal from "../../components/form-modal/FormModal";
import AllTickets from "../allTickets/AllTickets";
import MyTickets from "../myTickets/MyTickets";
import "./Dashboard2.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusCircle,
  faBoxesStacked,
  faFile,
  faHome,
} from "@fortawesome/free-solid-svg-icons";

const Dashboard2 = () => {
  return (
    <div className="wrapper bg-color-light">
      {/* <div className="container-fluid pt-4">
        <div className="d-flex justify-content-center w-100">
          <h2 className="text-center animation">Ticket System</h2>
        </div>
      </div> */}
      <Container className="pt-4" fluid>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Row>
            <Col lg={2} md={3} sm={4}>
              <div className="side-bar">
                <h4 className="pb-4 heading">Ticket System</h4>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link className="pills-item" eventKey="first">
                      <FontAwesomeIcon icon={faPlusCircle} className="px-2" />
                      <span className="">Generate</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="pills-item" eventKey="second">
                      <FontAwesomeIcon icon={faBoxesStacked} className="px-2" />
                      <span>My tickets</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="pills-item" eventKey="third">
                      <FontAwesomeIcon icon={faFile} className="px-2" />
                      <span>All tickets</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link className="pills-item">
                      <a href="https://finehhdigital.com/home" className="text-decoration-none">
                        <FontAwesomeIcon icon={faHome} className="px-2" />
                        <span>Home</span>
                      </a>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </Col>
            <Col lg={10} md={9} sm={8}>
              <div className="main-content">
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <FormModal />{" "}
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <MyTickets />{" "}
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <AllTickets />{" "}
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};

export default Dashboard2;
