import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import "./allTickets.css";

import { forwardRef } from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { getFeedback } from "../../components/actions";
import { url } from "../../components/baseUrl";
import { toast } from "react-toastify";
// import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFilter, faFilters } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

// For Pre defined Filters
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// For Date Picker Filter

import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Stack from "@mui/material/Stack";
import moment from "moment";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const AllTickets = () => {
  const [feedbackData, setFeedbackData] = useState([]);
  //total tickets
  const [totalFeedback, setTotalFeedback] = useState();
  //total Open
  const [totalOpen, setTotalOpen] = useState();
  //total Closed
  const [totalClosed, setTotalClosed] = useState();
  //total waiting
  const [totalWaiting, setTotalWaiting] = useState();

  //Trigger (clear button)
  const [trigger, setTrigger] = useState(false);

  const getFeedbackData = async () => {

    setStartDate(new Date())
    setEndDate(new Date())
    setPreDefinedFilter(null)

    const response = await getFeedback();
    setFeedbackData(response?.data?.data);
    // totalTickets
    setTotalFeedback(response?.data?.data?.length);
    // totalOpen
    const totalOpeTickets = response?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Open") {
        count++;
      }
      return count;
    });
    setTotalOpen(totalOpeTickets.filter(Boolean)?.length);
    //totalClosed
    const totalClosedTickets = response?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Closed") {
        count++;
      }
      return count;
    });
    setTotalClosed(totalClosedTickets.filter(Boolean)?.length);
    //totalWaiting
    const totalWaitingTickets = response?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Waiting") {
        count++;
      }
      return count;
    });
    setTotalWaiting(totalWaitingTickets.filter(Boolean)?.length);
    setTrigger(false);
    return response;
  };
  useEffect(() => {
    getFeedbackData();
  }, []);

  const columns = [
    {
      title: "TicketNo",
      field: "ticketNumber",
      editable: "never",
      filtering: false,
    },
    // { title: "Comment", field: "comment" ,filtering: false},
    {
      title: "SystemName",
      field: "systemName",
      editable: "never",
      filtering: false,
    },
    {
      title: "Summary",
      field: "summary",
      initialEditValue: "initial edit value",
      editable: "never",
      filtering: false,
    },
    {
      title: "Department",
      field: "departName",
      editable: "never",
      filtering: false,
    },
    {
      title: "Description",
      field: "description",
      editable: "never",
      filtering: false,
    },
    {
      title: "Priority",
      field: "priority",
      editable: "never",
      lookup: { High: "High", Medium: "Medium", Low: "Low" },
    },
    {
      title: "Status",
      field: "status",
      lookup: { Open: "Open", Closed: "Closed", Waiting: "Waiting" },
    },
    {
      title: "AssignedTo",
      field: "assignedTo",
      editable: "never",
      filtering: false,
    },
    {
      title: "Category",
      field: "category",
      editable: "never",
      filtering: false,
    },
    {
      title: "CreatedBy",
      field: "createdBy",
      editable: "never",
      filtering: false,
    },
    {
      title: "CreatedOn",
      field: "createdOn",
      editable: "never",
      filtering: false,
    },
    {
      title: "ClosedOn",
      field: "closedOn",
      editable: "never",
      filtering: false,
    },
    { title: "TimeSpent", field: "timeSpent", filtering: false },
    { title: "TimeToResolve", field: "timeToResolve", filtering: false },
  ];
  const handleSummary = async (filterTicketData) => {
    // feedback data
    setFeedbackData(filterTicketData.data?.data)
    // totalTickets
    setTotalFeedback(filterTicketData?.data?.data?.length);
    // totalOpen
    const totalOpeTickets = await filterTicketData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Open") {
        count++;
      }
      return count;
    });
    setTotalOpen(totalOpeTickets.filter(Boolean)?.length);
    //totalClosed
    const totalClosedTickets = await filterTicketData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Closed") {
        count++;
      }
      return count;
    });
    setTotalClosed(totalClosedTickets.filter(Boolean)?.length);
    //totalWaiting
    const totalWaitingTickets = await filterTicketData?.data?.data?.map((item) => {
      let count = 0;
      if (item.status === "Waiting") {
        count++;
      }
      return count;
    });
    setTotalWaiting(totalWaitingTickets.filter(Boolean)?.length);
    setTrigger(true);
  };

  const handlePreFilters = async (filter) => {
    console.log("clicked");
    let filterTicketData = await axios.get(`${url}/feedback/${filter}`);
    console.log(filterTicketData.data.data);
    setFeedbackData(filterTicketData?.data?.data);
    //call handle summary
    handleSummary(filterTicketData);
  };

  // const handlePreDefinedFilter = (e) => {
  const handlePreDefinedFilter = (value) => {
    // let value = e.target.value;
    switch (value) {
      case "Today":
        return handlePreFilters("todayTickets");
      case "Yesterday":
        return handlePreFilters("yesterDayTickets");
      case "LastWeek":
        return handlePreFilters("lastWeekTickets");
      case "BiWeekly":
        return handlePreFilters("lastBiWeekTickets");
      case "LastMonths":
        return handlePreFilters("lastMonthTickets");
      case "LastThreeMonths":
        return handlePreFilters("lastThreeMonthsTickets");
      default:
        break;
    }
  };

  // pre-defined filter state
  const [preDefinedFilter, setPreDefinedFilter] = useState("");

  // pre-define filter Function
  const handleChange = (event) => {
    setPreDefinedFilter(event.target.value);
    handlePreDefinedFilter(event.target.value);
  };

  // date filter state
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleDateFilter = async (newValue)=>{
    console.log(startDate);
    console.log(newValue);
    setEndDate(newValue)
    
    let filterTicketData = await axios.post(`${url}/feedback/filterAllTickets`, {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(newValue).format("YYYY-MM-DD")
    })
    // console.log("fetch Filter Date data >>> ",filterTicketData);
    handleSummary(filterTicketData)

  }
  return (
    // <div className="bg-color-light">
    <div>
      {/* <div className=""> */}
      <div className="all-tickets-container">
        <div className="d-flex w-100">
          <div className="w-100">
            <h2 className="px-5 py-3 text-center heading">All Tickets</h2>
          </div>
          {/* <div className="w-100 d-flex justify-content-around"> */}
          <div className="w-100 d-flex justify-content-end">
            {/* <div className="pre-defined-filter d-flex px-2 align-items-center"> */}
            <div className="pre-defined-filter d-flex px-2">
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: 120 }}
              >
                {/* <InputLabel id="demo-simple-select-standard-label"> */}
                <InputLabel id="demo-simple-select-helper-label">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <style
                      dangerouslySetInnerHTML={{
                        __html:
                          "\n  .sliders {\n\tanimation: sliders 1.5s cubic-bezier(0.860, 0.000, 0.070, 1.000) infinite alternate both;\n}\n@keyframes sliders {\n  0% {\n    transform: translateX(0px); \n  }\n  100% {\n    transform: translateX(10px); \n  }\n}\n  .sliders-2 {\n\tanimation: sliders-2 1.5s cubic-bezier(0.860, 0.000, 0.070, 1.000) infinite alternate both;\n}\n@keyframes sliders-2 {\n  0% {\n    transform: translateX(0px); \n  }\n  100% {\n    transform: translateX(-10px); \n  }\n}\n",
                      }}
                    />
                    <path
                      stroke="#000"
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M6 8.746h12M6 15.317h12"
                    />
                    <circle
                      className="sliders"
                      cx="7.5"
                      cy="8.746"
                      r="1.5"
                      fill="#000"
                      stroke="#000"
                      strokeWidth="1.5"
                    />
                    <circle
                      className="sliders-2"
                      cx="16.5"
                      cy="15.254"
                      r="1.5"
                      fill="#000"
                      stroke="#000"
                      strokeWidth="1.5"
                    />
                  </svg>
                  Filter
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  // labelId="demo-simple-select-standard-label"
                  // id="demo-simple-select-standard"
                  value={preDefinedFilter}
                  onChange={handleChange}
                  label="Filter"
                  className="filter-list"
                >
                  <MenuItem className="d-block px-2 pt-1" value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem className="d-block px-2 pt-1" value="Today">
                    Today
                  </MenuItem>
                  <MenuItem className="d-block px-2 pt-1" value="Yesterday">
                    Yesterday
                  </MenuItem>
                  <MenuItem className="d-block px-2 pt-1" value="LastWeek">
                    Last Week
                  </MenuItem>
                  <MenuItem className="d-block px-2 pt-1" value="BiWeekly">
                    BiWeekly
                  </MenuItem>
                  <MenuItem className="d-block px-2 pt-1" value="LastMonths">
                    Last Month
                  </MenuItem>
                  <MenuItem
                    className="d-block px-2 pt-1"
                    value="LastThreeMonths"
                  >
                    Last 3 Months
                  </MenuItem>
                </Select>
              </FormControl>
              {trigger ? (
                <span
                  onClick={getFeedbackData}
                  className="icon-btn mx-1 px-2 d-flex align-items-center"
                >
                  <FontAwesomeIcon icon={faTimesCircle} fontSize={25} />
                </span>
              ) : null}
            </div>
            <div className="d-flex align-items-center">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <div className="date-filter w-100 d-flex justify-content-evenly">
                    <div className="left-block date-picker px-1">
                      <DesktopDatePicker
                        // minDate={new Date("2017-01-01")}
                        label="From"
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                    <div className="right-block date-picker px-1">
                      <DatePicker
                        // disableFuture
                        // openTo="year"
                        // views={["year", "month", "day"]}
                        label="To"
                        value={endDate}
                        // onChange={(newValue) => {
                        //   setEndDate(newValue);
                        // }}
                        onChange={(newValue) => handleDateFilter(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </div>
                </Stack>
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <div className="row w-auto">
          <div className="col-lg-3 col-md-6 col-sm-12 border-black px-5 my-2">
            <div className="py-4 row d-flex justify-content-between align-items-center rounded h-100 all-tickets-card bg-total">
              <div className="col-6 w-100 mx-auto text-center px-0">
                <h5>
                  Total
                  {/* <br /> */}
                </h5>
                <h5>{totalFeedback ? totalFeedback : 0}</h5>
              </div>
              <div className="col-6 w-100 mx-auto text-center px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={34}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-layers"
                >
                  <polygon points="12 2 2 7 12 12 22 7 12 2" />
                  <polyline points="2 17 12 22 22 17" />
                  <polyline points="2 12 12 17 22 12" />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 border-black px-5 my-2">
            <div className="py-4 row d-flex justify-content-between align-items-center rounded h-100 all-tickets-card bg-closed">
              <div className="col-6 w-100 mx-auto text-center px-0">
                <h5>
                  Closed
                  {/* <br /> */}
                </h5>
                <h5>{totalClosed ? totalClosed : 0} </h5>
              </div>
              <div className="col-6 w-100 mx-auto text-center px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={34}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check-circle"
                >
                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                  <polyline points="22 4 12 14.01 9 11.01" />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 border-black px-5 my-2">
            <div className="py-4 row d-flex justify-content-between align-items-center rounded h-100 all-tickets-card bg-waiting">
              <div className="col-6 w-100 mx-auto text-center px-0">
                <h5>
                  Waiting
                  {/* <br /> */}
                </h5>
                <h5>{totalWaiting ? totalWaiting : 0}</h5>
              </div>
              <div className="col-6 w-100 mx-auto text-center px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={34}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-clock"
                >
                  <circle cx={12} cy={12} r={10} />
                  <polyline points="12 6 12 12 16 14" />
                </svg>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-12 border-black px-5 my-2">
            <div className="py-4 row d-flex justify-content-between align-items-center rounded h-100 all-tickets-card bg-open">
              <div className="col-6 w-100 mx-auto text-center px-0">
                <h5>
                  Open
                  {/* <br /> */}
                </h5>
                <h5>{totalOpen ? totalOpen : 0}</h5>
              </div>
              <div className="col-6 w-100 mx-auto text-center px-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={34}
                  height={34}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-repeat"
                >
                  <polyline points="17 1 21 5 17 9" />
                  <path d="M3 11V9a4 4 0 0 1 4-4h14" />
                  <polyline points="7 23 3 19 7 15" />
                  <path d="M21 13v2a4 4 0 0 1-4 4H3" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="all-tickets-container py-2">
        <MaterialTable
          style={{
            borderRadius: "15px",
            boxShadow: " 5px 5px 20px 5px rgb(0 0 0 / 10%)",
          }}
          title="All Tickets"
          columns={columns}
          icons={tableIcons}
          //   data={data}
          data={feedbackData}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...feedbackData];
                  const index = oldData.id;
                  dataUpdate[index] = newData;
                  axios
                    .post(`${url}/feedback/updateFeedback`, newData)
                    .then((res) => {
                      if (res.status === 200) {
                        console.log("Ticket updated Successfully");
                        console.log(newData);
                        getFeedbackData();
                        toast.success("Ticket Update Successfully");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        console.log("Network Error");
                        toast.error("Server Error!");
                        return;
                      } else if (error.response.status === 409) {
                        console.log("Ticket Already Exist");
                        return;
                      } else {
                        console.log("error");
                        toast.error("Error !");
                        return;
                      }
                    });

                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const id = oldData.id;
                  axios
                    .post(`${url}/feedback/deleteFeedback`, {
                      id: id,
                    })
                    .then((res) => {
                      if (res.status === 200) {
                        console.log("delted Successfully");
                        getFeedbackData();
                        toast.info("Ticket Delete Successfully");
                      } else {
                        console.log(res.data);
                        toast.error("Error !");
                      }
                    })
                    .catch((error) => {
                      // error from catch
                      console.log("error from catch ==>", error);
                      if (error.message === "Network Error") {
                        console.log("Server Error!");
                        // toast.error("Server Error!");
                        // setIsDisabled(false)
                        return;
                      } else {
                        console.log("error");
                        // toast.error("Error !");
                        return;
                      }
                    });
                  resolve();
                }, 1000);
              }),
          }}
          options={{
            filtering: true,
            pageSizeOptions: [
              5,
              10,
              20,
              { value: feedbackData.length, label: "All" },
            ],
            headerStyle: {
              // backgroundColor: "#66C9FF",
              // color: "#FFF",
              backgroundColor: "#d5e6ff",
            },
          }}
        />
      </div>
    </div>
  );
};

export default AllTickets;
